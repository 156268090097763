// eslint-disable-next-line max-classes-per-file
import * as React from 'react';
import * as History from 'history';
import { withRouter } from 'react-router-dom';
import { withPopoverManagerHOC, IPopoverManager } from '@inwink/modals/popovermgr';

import './sidethumbnail.less';

export interface ISideThumbnailProps {
    history?: History.History;
    popovermgr?: IPopoverManager;
    children?: React.ReactNode;
}

export interface ISideThumbnailContext {
    data: any;
    getState(): { enabled: boolean, minimized: boolean, data: any, prompting: boolean, restorePath: any };
    registerThumbnail(component: any, element: HTMLElement, data: any, restorePath : any, settings?: ISideThumbnailSettings);
    reattach(element: HTMLElement, restorePath: any);
    synchronise(element: HTMLElement);
    disable(element: HTMLElement);
    enable(element: HTMLElement);
    minimize(settings?: ISideThumbnailSettings, forceClear?: boolean);
    clear();
    prompt(canCancel?: boolean, settings?: ISideThumbnailSettings) : Promise<any>;
}

export interface ISideThumbnailSettings {
    onClose?: () => void;
    promptText? : string;
    minimizeText?: string;
    closeText?: string;
}

export interface ISideThumbnailState extends ISideThumbnailSettings {
    component: any;
    context: ISideThumbnailContext;
    minimized?: boolean;
    data?: any;
    style?: any;
    restorePath? : any;
    enabled?: boolean;
}

export const SideThumbnailContext = React.createContext<ISideThumbnailContext>(null);

class SideThumbnailComponent extends React.Component<ISideThumbnailProps, ISideThumbnailState> {
    prompting: boolean;

    constructor(props) {
        super(props);
        this.state = {
            component: null,
            context: this.getContext()
        };
    }

    getPositionForElement = (element: HTMLElement) => {
        if (element) {
            const rect = element.getBoundingClientRect();
            return {
                position: "fixed",
                left: rect.left,
                top: rect.top,
                width: rect.width,
                height: rect.height,
            };
        }
    };

    getContext(data?: any) {
        const thumbContext : ISideThumbnailContext = {
            data: data,
            getState: () => {
                return {
                    enabled: this.state.enabled,
                    minimized: this.state.minimized,
                    data: this.state.data,
                    prompting: this.prompting,
                    restorePath: this.state.restorePath
                };
            },

            registerThumbnail: (component, element, dt, restorePath, settings) => {
                this.prompting = false;
                const style = this.getPositionForElement(element);

                if (this.state.component && this.state.onClose) {
                    this.state.onClose();
                }

                const statePatch = Object.assign({
                    component: component,
                    data: dt,
                    enabled: true,
                    style: style,
                    minimized: false,
                    context: this.getContext(dt),
                    restorePath: restorePath
                }, settings);

                this.setState(statePatch);
            },

            reattach: (element, restorePath) => {
                if (this.state.component) {
                    const style = this.getPositionForElement(element);
                    this.setState({ style: style, minimized: false, restorePath: restorePath });
                }
            },

            enable: () => {
                this.setState({ enabled: true });
            },
            disable: () => {
                this.setState({ enabled: false });
            },
            synchronise: (element) => {
                if (this.state.component) {
                    if (element && !this.state.minimized) {
                        const style = this.getPositionForElement(element);

                        this.setState({ style: style });
                    }
                }
            },

            minimize: (settings, forceClear?) => {
                if (!this.state.enabled || forceClear) {
                    this.clear();
                    return;
                }

                if (this.state.component) {
                    const statePatch = Object.assign({
                        minimized: true,
                        style: null
                    }, settings);
                    this.setState(statePatch);
                }
            },

            clear: () => {
                this.clear();
            },

            prompt: (canCancel: boolean, settings) => {
                if (!this.prompting && !this.state.minimized) {
                    this.prompting = true;

                    if (this.state.component) {
                        return import('./sidethumbnail.prompt').then((mod) => {
                            return this.props.popovermgr.modalPortal(mod.SideThumbnailPrompt, {
                                canCancel: canCancel,
                                txt: (settings && settings.promptText) || this.state.promptText,
                                minimizeText: (settings && settings.minimizeText) || this.state.minimizeText,
                                closeText: (settings && settings.closeText) || this.state.closeText
                            }, "iw-sidethumbnail-prompt").then((res) => {
                                this.prompting = false;
                                if (res === "minimize") {
                                    const statePatch = Object.assign({
                                        minimized: true
                                    }, settings);
                                    this.setState(statePatch, () => {
                                        this.prompting = false;
                                    });
                                    return true;
                                } if (res === "close") {
                                    const closing = settings.onClose || this.state.onClose;
                                    if (closing) {
                                        closing();
                                    }
                                    this.clear();

                                    return true;
                                }
                                return false;
                            }, () => {
                                this.prompting = false;
                            });
                        });
                    }
                }

                return Promise.resolve();
            }
        };

        return thumbContext;
    }

    clear = () => {
        this.prompting = false;
        this.setState({
            component: null,
            data: null,
            style: null,
            onClose: null,
            context: this.getContext(),
            restorePath: null,
            minimized: false
        });
    };

    restore = () => {
        this.props.history.push(this.state.restorePath);
    };

    close = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        if (this.state.onClose) {
            this.state.onClose();
        }
        this.clear();
    };

    renderSideThumbnail = () => {
        if (!this.state.component) {
            return null;
        }

        const content = React.createElement(this.state.component);
        if (this.state.minimized) {
            return <div className="iw-sidethumbnail clickable minimized" onClick={this.restore}>
                <div className="iw-sidethumbnail-content">
                    {content}
                    <button type="button" className="iw-sidethumbnail-close" onClick={this.close}>
                        <i className="inwink-dialog-cancel" />
                    </button>
                </div>

            </div>;
        }
        return <div className="iw-sidethumbnail full" style={this.state.style}>
            <div className="iw-sidethumbnail-content">
                {content}
            </div>
        </div>;
    };

    render() {
        return <SideThumbnailContext.Provider value={this.state.context}>
            {this.props.children}
            {this.renderSideThumbnail()}
        </SideThumbnailContext.Provider>;
    }
}

export const SideThumbnail: React.ComponentClass<ISideThumbnailProps, any> = withRouter(
    withPopoverManagerHOC(SideThumbnailComponent)
);
