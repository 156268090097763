import * as React from 'react';
import { connect } from 'react-redux';
import { I18NResourcesContext } from '@inwink/i18n/resourcecontext';
import { States } from '@@services/services';
import { getTrads } from '@@data/templates';

export interface IEventTradsContextProps {
    event?: States.IEventState;
    children?: React.ReactNode;
}

class EventTradsContextComponent extends React.PureComponent<IEventTradsContextProps, any> {
    constructor(props: IEventTradsContextProps) {
        super(props);
        this.state = {
            trads: getTrads(this.props.event?.data)
        };
    }

    componentDidUpdate(prevprops: IEventTradsContextProps) {
        if (prevprops.event !== this.props.event) {
            const trads = getTrads(this.props.event?.data);
            if (trads !== this.state.trads) {
                this.setState({ trads: trads });
            }
        }
    }

    render() {
        return <I18NResourcesContext
            resources={this.state.trads && this.state.trads.labels}
        >
            {this.props.children}
        </I18NResourcesContext>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event
    };
}

export const EventTradsContext: new (any) => React.Component<IEventTradsContextProps, any> = connect(
    mapStateToProps,
    null
)(EventTradsContextComponent as any) as any;
