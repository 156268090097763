import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { VisualConfiguration } from '@inwink/visualconfiguration';
import { IAppShellEventProps } from "./appshell.event.props";
import { Page } from '../../../components/page';
import { DynamicPage } from '../../../components/dynamicpage/dynamicpage';
import { AppUserMessage } from '../../../components/appusermessage';
import { actions as pageActions } from '../../../services/pageservice';

export interface IAppShellRestrictedEventProps extends IAppShellEventProps {
    pageActions? : typeof pageActions;
}

class AppShellRestrictedEventComponent extends React.Component<IAppShellRestrictedEventProps, any> {
    constructor(props: IAppShellRestrictedEventProps) {
        super(props);
        this.props.pageActions.initCurrentPage(null, "nouserlogged", {}) as any;
    }

    render() {
        return <div className={"app-layout event-" + (this.props.event.detail ? this.props.event.detail.id : "ukn")}
        >
            <VisualConfiguration
                visualConfiguration={this.props.visualConfiguration}
                prefix={".event-" + (this.props.event.detail ? this.props.event.detail.id : "") + " "}
            />
            <div className="app-content">
                <Page {...this.props} className="nouserloggedpage">
                    <DynamicPage {...this.props} template="companion.page.nouserlogged" />
                </Page>
            </div>
            <AppUserMessage />
        </div>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
}

export const AppShellRestrictedEvent: new (any)
=> React.Component<IAppShellRestrictedEventProps, any> = connect(
    null,
    mapDispatchToProps
)(AppShellRestrictedEventComponent as any) as any;
