import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parse } from '@inwink/utils/querystring';
import type { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import { AppHeader } from '@@components/appheader';
import { AppMenu } from '@@components/appmenu';
import { AppUserMessage } from '@@components/appusermessage';
import type { IDefaultEventPageProps } from '@@data/visualtheme';
import type { States } from '@@services/services';
import { actions as pageActions } from '@@services/pageservice';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { ModalThemeContext } from '@@components/modalthemecontext';
import { SideThumbnail } from '@@components/sidethumbnail';
import {withToastProvider} from "@@components/toast/withToastProvider";
import { hasItemsInMenu } from '@@modules/helpers';
import { AppShellEventRoutes } from './appshell.routes';
import { AppShellRestrictedEvent } from './appshell.restricted';
import type { IAppShellEventProps } from './appshell.event.props';
import { EventRealtime } from '../components/eventrealtime';
import { AppHeaderEvent } from '../components/appheader';
import {
    RoundTableManager
} from '../components/roundtabledatacontext';
import AppMenuLanguagesControl from '@@components/appmenulanguages';

const MobileFooter = React.lazy(() => import('@@components/mobilefooter'));
const uilogger = logging.getLogger("UI.EventShell");

interface IAppShellEventState {
    isNotLegitUser: boolean;
    chromeless: boolean;
    datacontext: Entities.IPageDataContext;
    eventTimeline: JSX.Element;
    initialized: boolean;
}

class AppShellEventComponent extends React.Component<IAppShellEventProps, IAppShellEventState> {
    EventTimeline: () => any;

    previousPage: States.ICurrentPageState;

    reloading: boolean;

    constructor(props: IAppShellEventProps) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        uilogger.verbose("Init event shell");
        const query = parse(props.location.search, null);
        this.state = {
            isNotLegitUser: this.checkUserStatus(props),
            chromeless: !!query._chromeless,
            datacontext: this.getDataContext(props),
            eventTimeline: null,
            initialized: false
        };
    }

    loadMenu = () => {
        this.props.metadataMenuActions.loadMenu();
    };

    toggleMenu = () => {
        this.props.metadataMenuActions.toggleMenu();
    };

    getDataContext(props: IAppShellEventProps) : Entities.IPageDataContext {
        const res: Entities.IPageDataContext = {
            communitystate: null,
            eventstate: props.event,
            userstate: props.user,
            event: props.event?.detail,
            user: props.user.currentUser ? props.user.currentUser.detail : null,
            i18nstate: props.i18n,
            fieldtemplate: null
        };

        return res;
    }

    getEventTimeline = () => {
        if (this.props.eventtimeline) {
            import(
                /* webpackChunkName: "mod-eventtimeline" */
                "../components/appheader/bloc.eventtimeline"
            ).then((tm) => {
                this.setState({
                    eventTimeline: <tm.BlocEventTimeline
                        event={this.props.event}
                        template={this.props.eventtimeline}
                        i18n={this.props.i18n}
                        visualConfiguration={this.props.visualConfiguration}
                    />
                });
            });
        }
    };

    componentDidMount() {
        this.getEventTimeline();
        if (!this.state.initialized) {
            this.setState({ initialized: true});
        }
    }

    componentDidUpdate(prevProps: IAppShellEventProps) {
        if (this.props.location.search !== prevProps.location.search) {
            const query = parse(this.props.location.search, null);
            const chromeless = !!query._chromeless;
            if (chromeless !== this.state.chromeless) {
                this.setState({ chromeless: chromeless });
            }
        }

        if ((prevProps.eventtimeline !== this.props.eventtimeline && this.props.eventtimeline)
            || (this.state.eventTimeline && prevProps.event?.detail !== this.props.event?.detail)) {
            this.getEventTimeline();
        }

        // if (this.props.event !== prevProps.event) {
        //     this.setState({
        //         roundTableContext: EventRoundTableDataContextInstance(this.props.event,
        //             this.props.realtimeRegistrationsActions)
        //     });
        // }

        if (this.props.user !== prevProps.user || this.props.event !== prevProps.event) {
            this.setState({
                datacontext: this.getDataContext(this.props)
            });
            const isNotLegitUser = this.checkUserStatus(this.props);
            if (isNotLegitUser !== this.state.isNotLegitUser) {
                this.setState({ isNotLegitUser: isNotLegitUser });
            }
        }

        /*
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.userChatActions.clearOffsetsChanged();
        }
        */
    }

    renderEvent() {
        const pageArgs: IDefaultEventPageProps = {
            // key: key,
            location: this.props.location,
            match: this.props.match,
            event: this.props.event,
            user: this.props.user,
            i18n: this.props.i18n,
            webmasterConfiguration: this.props.webmasterConfiguration,
            visualConfiguration: this.props.visualConfiguration,
            page: this.props.page,
            pagefooter: this.props.pagefooter,
            visualstate: this.props.visualstate
        };

        let header;
        let footer;
        let menu;
        let burgerbtn;
        let menuStyle;
        let eventTimeline;
        const classes = ["app-layout"];
        const displayBurgerBtn = hasItemsInMenu(this.props.pageheader);

        if (this.state.chromeless) {
            classes.push("chromeless");
        }

        const firstrender = !!(global as any).INITIAL_STATE;

        if (!firstrender && this.props.mobilefooter && this.props.visualstate
            && (!(this.props.visualstate.indexOf("M") >= 0)) && !this.state.chromeless) {
            classes.push("with-mobilefooter");
            if (this.state.initialized) {
                footer = <React.Suspense fallback={<></>}>
                    <MobileFooter
                        visualConfiguration={this.props.visualConfiguration}
                        footerTemplate={this.props.mobilefooter}
                        event={this.props.event}
                        rootwebsite={this.props.rootwebsite}
                        user={this.props.user}
                        i18n={this.props.i18n}
                        location={this.props.location}
                        match={this.props.match}
                    />
                </React.Suspense>;
            }
        }

        if (this.props.event && this.props.event.detail) {
            classes.push("event-" + this.props.event.detail.id);
            if (!this.state.chromeless) {
                menuStyle = {
                    backgroundColor: "transparent",
                    color: this.props.visualConfiguration?.appHeader?.style?.color || ""
                };
                header = <AppHeader
                    visualConfiguration={this.props.visualConfiguration}
                    headerTemplate={this.props.pageheader}
                    datacontext={this.state.datacontext}
                    copilotConfiguration={this.props.copilotConfiguration}
                    title={this.props.event?.detail?.title}
                    headerSettings={this.props.event?.detail?.configuration?.companion?.headerSettings}
                    hasBurgerBtn={displayBurgerBtn}
                >
                    <AppHeaderEvent
                        copilotConfiguration={this.props.copilotConfiguration}
                        visualConfiguration={this.props.visualConfiguration}
                        headerTemplate={this.props.pageheader}
                    />
                </AppHeader>;
                burgerbtn = <div
                    className="burger-menu"
                    id="burger-menu"
                    onMouseEnter={this.loadMenu}
                    onClick={this.toggleMenu}
                    style={menuStyle}
                >
                    <i className="inwink-menu" />
                </div>;
                menu = <AppMenu
                    {...pageArgs}
                    datacontext={this.state.datacontext}
                    menuTemplate={this.props.pageheader}
                    onHide={this.toggleMenu}
                >
                    <AppMenuLanguagesControl />
                </AppMenu>;
            }
            if (this.props.eventtimeline && this.state.eventTimeline) {
                eventTimeline = this.state.eventTimeline;
            }
        }

        return <EventRealtime>
            <ModalThemeContext
                visualConfiguration={this.props.visualConfiguration}
                prefix={"event-" + this.props.event.eventid}
            >
                <div className={classes.join(" ")}>
                    <RoundTableManager>
                        <div className="app-content">
                            <SideThumbnail>
                                {header}
                                {eventTimeline}
                                <AppShellEventRoutes {...this.props} />
                                {footer}
                            </SideThumbnail>
                        </div>
                        <AppUserMessage />
                        {displayBurgerBtn && burgerbtn}
                        {menu}
                    </RoundTableManager>
                </div>

            </ModalThemeContext>
        </EventRealtime>;
    }

    test = () => {
        try {
            throw new Error("oups I did it again");
        } catch (e) {
            logging.warn("test logs", { test: true }, e);
        }
    };

    checkUserStatus(props: IAppShellEventProps) {
        let isNotLegitUser = false;
        if (props.event && props.event.detail) {
            const conf = props.event.detail.configuration;
            if (conf && conf.companion && conf.companion.requireAuthenticationForContent) {
                if (__SERVERSIDE__) return true;

                isNotLegitUser = !props.user || !props.user.currentUser || !props.user.currentUser.detail;
                if (!isNotLegitUser) {
                    const hasValidKind = (!!props.user.currentUser.detail.kinds && props.user.currentUser.detail.kinds.length)
                    || props.user.currentUser.detail.isRegistered === true;
                    isNotLegitUser = !hasValidKind;
                }
            }
        } else {
            isNotLegitUser = true;
        }

        if (isNotLegitUser) {
            this.previousPage = props.page;
        }

        return isNotLegitUser;
    }

    render() {
        uilogger.verbose("render event shell");
        if (this.state.isNotLegitUser) {
            return <AppShellRestrictedEvent {...this.props} />;
        }

        return this.renderEvent();
    }
}

function mapEventShellStateToProps(state: States.IAppState) {
    return {
        // appMetaData: state.appMetaData,
        userChat: state.userChat
    };
}

function mapEventShellDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        metadataMenuActions: bindActionCreators(metadataMenuActions, dispatch),
    };
}

export const AppShellEvent: React.ComponentClass<IAppShellEventProps, IAppShellEventState> = connect(
    mapEventShellStateToProps,
    mapEventShellDispatchToProps
)(
    withToastProvider(AppShellEventComponent) as any
) as any;
