/* eslint-disable max-classes-per-file */
import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';
import { States } from '../../../services/services';
import { IRealtimeManager } from '../../../services/realtimeservice';

export interface IRoundTableManagerContext {
    eventId?: string;
    hall?: string;
    table?: string;
    exhibitorId?: string;
    personId?: string;
    disableRealtime?: boolean;
}
export interface IRoundTableContext {
    getManager(
        owner: any,
        context: IRoundTableManagerContext,
        dataChanged: () => void
    ) : { instance: Promise<IRoundTableDataContextInstance>, release: () => void};
    releaseManager(owner: any, context: IRoundTableManagerContext);
}

export interface IRoundTableDataContextInstance {
    realtime: Promise<IRealtimeManager>;
    tables: Entities.IRoundTable[];
    getTables(): Promise<Entities.IRoundTable[]>;
    userChanged();
    dispose();
    onTableChange(tableId: string, callback: (table: Entities.IRoundTable) => void): () => void;
    registerChangeHandler(callback: () => void) : () => void;
    removeChangeHandler(callback: () => void);
}

export interface IFilterRoundTable extends Entities.IRoundTable {
    $startDate?: moment.Moment;
    $endDate?: moment.Moment;
}

export class RoundTableContext implements IRoundTableContext {
    currentManagers: Record<string, {
        instance: Promise<IRoundTableDataContextInstance>;
        owners: any[];
        timeout: any;
    }> = {};

    constructor(private getState: () => States.IAppState) {

    }

    private getManagerKey(context: IRoundTableManagerContext) {
        // eslint-disable-next-line max-len
        return `${context.eventId}#${context.hall || ""}#${context.table || ""}#${context.exhibitorId || ""}#${context.personId || ""}#${context.disableRealtime || ""}`;
    }

    private getInst() {
        // eslint-disable-next-line import/no-cycle
        return import('./roundtabledatacontextinstance');
    }

    getManager(owner: any, context: IRoundTableManagerContext, dataChanged: () => void)
        : { instance: Promise<IRoundTableDataContextInstance>, release: () => void} {
        const key = this.getManagerKey(context);
        let existingItem = this.currentManagers[key];
        if (!existingItem) {
            existingItem = {
                instance: this.getInst().then((mod) => new mod.RoundTableDataContextInstance(context, this.getState)),
                owners: [],
                timeout: null
            };
            this.currentManagers[key] = existingItem;
        } else if (existingItem.timeout) {
            clearTimeout(existingItem.timeout);
            existingItem.timeout = null;
        }

        const idx = existingItem.owners.indexOf(owner);
        if (idx < 0) {
            existingItem.owners.push(owner);
        }
        let unregisterHandler = null;

        if (dataChanged) {
            existingItem.instance.then((inst) => {
                unregisterHandler = inst.registerChangeHandler(dataChanged);
            });
        }

        return {
            instance: existingItem.instance,
            release: () => {
                existingItem.instance.then(() => {
                    if (unregisterHandler) {
                        unregisterHandler();
                    }
                    this.releaseManager(owner, context);
                });
            }
        };
    }

    releaseManager(owner: any, context: IRoundTableManagerContext) {
        const key = this.getManagerKey(context);
        const existingItem = this.currentManagers[key];
        if (existingItem) {
            const idx = existingItem.owners.indexOf(owner);
            if (idx >= 0) {
                existingItem.owners.splice(idx, 1);
            }

            if (existingItem.owners.length === 0 && !existingItem.timeout) {
                existingItem.timeout = setTimeout(() => {
                    existingItem.instance.then((inst) => {
                        inst.dispose();
                    });

                    delete this.currentManagers[key];
                }, 4000);
            }
        }
    }
}
