import * as React from 'react';
import { useState } from 'react';
import { useStore } from 'react-redux';
import { Entities } from '@inwink/entities/entities';
import { IRoundTableContext, IRoundTableDataContextInstance, RoundTableContext } from '../data/roundtable.datamanagers';

export type { IRoundTableContext };
export type { IRoundTableDataContextInstance } from '../data/roundtable.datamanagers';
export type IRoundTable = Entities.IRoundTable;

export const RoundTableDataContext = React.createContext<IRoundTableContext>(null);
export const RoundTableInstanceContext = React.createContext<Promise<IRoundTableDataContextInstance>>(null);

export function RoundTableManager(props: React.PropsWithChildren<any>) {
    const store = useStore();
    const [roundTableCtx] = useState(() => new RoundTableContext(store.getState as any));

    return <RoundTableDataContext.Provider value={roundTableCtx}>
        {props.children}
    </RoundTableDataContext.Provider>;
}

export function withRoundTableManager() {
    return (target) => {
        // eslint-disable-next-line react/display-name
        return React.forwardRef((props, ref) => <RoundTableDataContext.Consumer>
            {(roundTableManager) => React.createElement(target, { ...props, roundTableManager: roundTableManager, ref: ref })}
        </RoundTableDataContext.Consumer>) as typeof target;
    };
}

export function withRoundTableInstance() {
    return (target) => {
        // eslint-disable-next-line react/display-name
        return React.forwardRef((props, ref) => <RoundTableInstanceContext.Consumer>
            {(roundTableInstance) => React.createElement(target, { ...props, roundTableInstance: roundTableInstance, ref: ref })}
        </RoundTableInstanceContext.Consumer>) as typeof target;
    };
}
