import { VisualConfigurationContextProvider } from '@inwink/visualconfiguration';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { PopoverDisplayContext, IPopoverDisplayOptionsContext } from '@inwink/modals/popoverdisplaycontext';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import {
    getMobileFooter, getPageFooter, getPageHeader, getCopilot,
    getVisualConfiguration, getWebmaster, getEventTimeline
} from '@@data/templates';
import type { States } from '@@services/services';
import { actions as i18nActions } from '@@services/i18nservice';
import { ContentInjection } from '@@components/contentinjection';
import { EventTradsContext } from '@@event/components/eventtradscontext';
import { UrlStateContext } from '@@components/urlstatecontext';
import { AppEventData } from './appeventdata';
import { AppShellEvent } from './appshell.event';
import type { IAppShellProps } from './appshell.event.props';

import '../../../styles/reset.less';
import '../../../styles/appthemes.less';
import '../../../styles/transitions.less';
import '../../../styles/appshell.less';
import { AiToolsContext, IAiToolsContext, IAiToolsRequest } from '@inwink/aiinputs/aitoolscontext';
import { defaultPostHeaderForJsonData } from '@@api/index';

export interface IAppShellState {
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    webmaster: VisualTheme.IWebmasterConfig;
    copilot: States.ICopilotConfiguration;
    pageheader: Entities.IContentTemplate;
    pagefooter: Entities.IContentTemplate;
    mobilefooter: Entities.IContentTemplate;
    popoverOptions: IPopoverDisplayOptionsContext;
    eventtimeline: any;
    tracking: {
        scripts: any[];
    };
}

class AppShellComponent extends React.Component<IAppShellProps, IAppShellState> {
    constructor(props: IAppShellProps) {
        super(props);

        // logger.debug("Create AppShell");
        let tracking;
        if (props.tracking && props.tracking.trackers && props.tracking.trackers.length) {
            tracking = {
                scripts: props.tracking.trackers
                    .filter((t) => t.partitionId === this.props.event?.eventid)
                    .map((t) => t.script).filter((t) => !!t)
            };
        }

        if (__SERVERSIDE__) {
            if (props.match.params.lngcode && props.match.params.lngcode !== this.props.i18n.currentLanguageCode) {
                this.props.i18nActions.loadLanguage(props.match.params.lngcode, null, true);
            }
        }

        const vconf = this.props.event?.data ? getVisualConfiguration(this.props.event.data) : null;
        this.state = {
            visualConfiguration: vconf,
            webmaster: this.props.event?.data ? getWebmaster(this.props.event.data) : null,
            copilot: this.props.event?.data ? getCopilot(this.props.event.data) : null,
            pageheader: this.props.event?.data ? getPageHeader(this.props.event.data) : null,
            pagefooter: this.props.event?.data ? getPageFooter(this.props.event.data) : null,
            mobilefooter: this.props.event?.data ? getMobileFooter(this.props.event.data) : null,
            eventtimeline: this.props.event?.data ? getEventTimeline(this.props.event.data) : null,
            // eventtrads: this.props.event.data ? getTrads(this.props.event.data) : null,
            tracking: tracking,
            popoverOptions: this.getPopoverOptions(vconf)
        };
    }

    componentDidMount(): void {
        const tmp = (global as any);
        if (typeof tmp.inwinkEventBootstrap !== "undefined") {
            tmp.inwinkEventBootstrap = undefined;
        }
    }

    getPopoverOptions(visualConfiguration: Entities.IVisualConfigurationTemplate) {
        const res: IPopoverDisplayOptionsContext = {
            modal: {
                containerClassName: "event-" + this.props.event?.eventid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.modalTheme ? " bloctheme-" + visualConfiguration.global.modalTheme : "")
            },
            popover: {
                containerClassName: "event-" + this.props.event?.eventid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.popoverTheme ? " bloctheme-" + visualConfiguration.global.popoverTheme : "")
            },
            sidemodal: {
                containerClassName: "event-" + this.props.event?.eventid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.modalTheme ? " bloctheme-" + visualConfiguration.global.modalTheme : "")
            }
        };
        return res;
    }

    componentDidUpdate(prevprops: IAppShellProps, prevstate) {
        let patch = null;

        if (this.props.tracking
            && prevprops.tracking !== this.props.tracking
            && this.props.tracking.trackers
            && this.props.tracking.trackers.length
        ) {
            patch = patch || {};
            patch.tracking = {
                scripts: this.props.tracking.trackers
                    .filter((t) => t.partitionId === this.props.event?.eventid)
                    .map((t) => t.script).filter((t) => !!t)
            };
        }

        if (this.props.event) {
            if (prevprops.event !== this.props.event || !this.state.visualConfiguration || !this.state.webmaster) {
                const visualconfig = getVisualConfiguration(this.props.event.data);
                if (visualconfig && prevstate.visualConfiguration !== visualconfig) {
                    patch = patch || {};
                    patch.visualConfiguration = visualconfig;
                    patch.popoverOptions = this.getPopoverOptions(visualconfig);
                }

                const webmaster = getWebmaster(this.props.event.data);
                if (webmaster && webmaster !== prevstate.webmaster) {
                    patch = patch || {};
                    patch.webmaster = webmaster;
                }

                const copilot = getCopilot(this.props.event.data);
                if (copilot && copilot !== prevstate.copilot) {
                    patch = patch || {};
                    patch.copilot = copilot;
                }

                const pageheader = getPageHeader(this.props.event.data);
                if (pageheader && pageheader !== prevstate.pageheader) {
                    patch = patch || {};
                    patch.pageheader = pageheader;
                }


                const pagefooter = getPageFooter(this.props.event.data);
                if (pagefooter && pagefooter !== prevstate.pagefooter) {
                    patch = patch || {};
                    patch.pagefooter = pagefooter;
                }

                const mobilefooter = getMobileFooter(this.props.event.data);
                if (mobilefooter && mobilefooter !== prevstate.mobilefooter) {
                    patch = patch || {};
                    patch.mobilefooter = mobilefooter;
                }

                const eventtimeline = getEventTimeline(this.props.event.data);
                if (eventtimeline && eventtimeline !== prevstate.eventtimeline) {
                    patch = patch || {};
                    patch.eventtimeline = eventtimeline;
                }
            }
        }

        if (patch) {
            this.setState(patch);
        }
    }

    render() {
        // logger.verbose("render app shell");

        const isServerSide = __SERVERSIDE__;        

        return <PopoverDisplayContext.Provider value={this.state.popoverOptions}><UrlStateContext
            baseUrl={this.props.match?.url}
            i18n={this.props.i18n}
            noLanguage={!!this.props.match?.params?.lngcode}
        >
            <VisualConfigurationContextProvider visualConfiguration={this.state.visualConfiguration}>
                <EventAiTools {...this.props}>
                    <EventTradsContext>
                        <AppEventData
                            visualConfiguration={this.state.visualConfiguration}
                            user={this.props.user}
                            i18n={this.props.i18n}
                            event={this.props.event}
                            rootwebsite={this.props.rootwebsite}
                            eventid={this.props.event?.eventid}
                            targetTinyurl={this.props.targetEvent}
                            tinyurl={this.props.match && this.props.match.params.eventtinyurl}
                            location={this.props.location}
                            match={this.props.match}
                        >
                            <Helmet />

                            <AppShellEvent
                                {...this.props}
                                webmasterConfiguration={this.state.webmaster}
                                visualConfiguration={this.state.visualConfiguration}
                                pageheader={this.state.pageheader}
                                pagefooter={this.state.pagefooter}
                                mobilefooter={this.state.mobilefooter}
                                eventtimeline={this.state.eventtimeline}
                                copilotConfiguration={this.state.copilot}
                                content={this.props.children}
                            />
                            <ContentInjection
                                id="webmaster"
                                inject={this.state.webmaster?.afterBody}
                                serverSide={isServerSide}
                                hostId="script-injection-placeholder"
                            />
                            <ContentInjection id="tracking" inject={this.state.tracking} serverSide={isServerSide} />
                        </AppEventData>
                    </EventTradsContext>
                </EventAiTools>
            </VisualConfigurationContextProvider>
        </UrlStateContext></PopoverDisplayContext.Provider>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        rootwebsite: state.rootwebsite,
        user: state.user,
        page: state.pages.currentPage,
        i18n: state.i18n,
        tracking: state.tracking
    };
}

function mapDispatchToProps(dispatch) {
    return {
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const AppShellContent = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppShellComponent as any) as any);

function AppShellEventLanguages(props: IAppShellProps) {
    if (props.event?.detail?.configuration?.global?.supportedLanguages
        && props.event.detail.configuration.global.supportedLanguages.length > 1) {
        const rootPath = (props.match.path) || "";
        let lngRootPath = rootPath;
        if (lngRootPath === "/") {
            lngRootPath = "";
        }
        const routes = props.event.detail.configuration.global.supportedLanguages.map((lng) => {
            return <Route
                key={lng}
                path={lngRootPath + "/" + lng}
                render={(rprops) => {
                    const routeprops = rprops;
                    routeprops.match.path = lngRootPath + "/:lngcode";
                    routeprops.match.params.lngcode = lng;
                    return <AppShellContent {...props} {...routeprops} />;
                }}
            />;
        });
        routes.push(<Route
            key="default"
            path={rootPath}
            render={(rprops) => {
                const routeprops = rprops;
                return <AppShellContent {...props} {...routeprops} />;
            }}
        />);
        return <Switch location={props.location}>
            {routes}
        </Switch>;
    }
    return <AppShellComponent {...props} />;
}

export const AppShell = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppShellEventLanguages as any) as any);
export default AppShell;

function EventAiTools(props: IAppShellProps & {children: any}) {
    const [aiContext, setAiContext] = React.useState<IAiToolsContext>(null);
    React.useEffect(() => {
        const customerId = props.event?.detail?.customerId;
        const tenantId = props.event?.detail?.tenantId;
        const eventId = props?.event?.eventid;
    
        if (customerId && tenantId && eventId && props.event.detail?.features?.ai === true) {            
            const aiToolsRequest = (request: IAiToolsRequest) => {
                const bcontext = [];
                if (props.event.detail?.title) {
                    bcontext.push("**Event Name:** "
                        + props.event.detail?.title);                        
                }
                const lng = props.event.detail?.configuration?.global?.defaultLanguage;
                if (lng && props.event.detail.description && props.event.detail.description[lng]) {
                    bcontext.push("**Event Description (in '" + lng + "')**"
                        + props.event.detail.description[lng]);                        
                }
                if (request.businessContext){
                    bcontext.push(request.businessContext);
                }

                return props.event.requestManagers.apiFront.postJson<Response>(
                    `aitools/event/${customerId}/${tenantId}/${eventId}`, 
                    JSON.stringify({
                        currentUserLanguage: props.i18n.currentLanguageCode,
                        ...request,
                        businessContext: bcontext.join("\r\n")
                    }), 
                    defaultPostHeaderForJsonData);
            };
    
            setAiContext({ sendRequest: aiToolsRequest });
            
        }
    }, [props?.event?.eventid, props.event.detail?.features?.ai]);
    

    return <AiToolsContext.Provider value={aiContext}>{
        props.children
    }</AiToolsContext.Provider>;
}