import type { Entities } from '@inwink/entities/entities';
import { VisualConfiguration } from '@inwink/visualconfiguration';
import { logging } from '@inwink/logging';
import * as React from 'react';
import { connect } from 'react-redux';
import * as debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { parse } from '@inwink/utils/querystring';
import { actionQueue } from '../../../actionsqueue';
import { eventActionQueueModule, eventUserBootstrapModule, eventModule } from '../../../routes/appmodules';
import { actions as i18nActions } from '../../../services/i18nservice';
import { actions as pageActions } from '../../../services/pageservice';
import type { States } from '../../../services/services';
import { wrapReduxStore, IInwinkStore } from '../../../store';
import { actions as userActions } from '../../../services/userservice';
import { CookieTracking } from '../../../components/cookiebar';
import { eventMailTracking } from '../../../api/front/mailtracking';
import * as moment from 'moment';
import { toUrlSlug } from '@inwink/utils/methods';
import { isFromServer } from '@@pages/helpers';
import { GuidPattern } from '@@services/index';

const uilogger = logging.getLogger("UI.EventShellData");

export interface IAppEventDataProps {
    className?: string;
    targetTinyurl?: string;
    tinyurl?: string;
    eventid?: string;
    event?: States.IEventState;
    rootwebsite?: States.IRootWebsiteState;
    user?: States.IAppUserState;
    i18n?: States.i18nState;
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    eventData?: States.IEventDataStore;
    // syncEventActions?: typeof syncEventActions;
    // bootstrapEventActions?: typeof bootstrapEventActions;
    // initEventActions?: typeof initEventActions;
    pageActions?: typeof pageActions;
    userActions?: typeof userActions;
    i18nActions?: typeof i18nActions;
    location: States.ILocation;
    match: States.ILocationMatch;
    store?: IInwinkStore;
    children?: React.ReactNode;
}

export interface IAppEventDataState {
}

class AppEventDataComponent extends React.Component<IAppEventDataProps, IAppEventDataState> {
    syncEventData: boolean;

    onboardingDisplay: boolean;

    container = React.createRef<HTMLDivElement>();

    constructor(props: IAppEventDataProps) {
        super(props);

        uilogger.verbose("Init event data");

        this.state = {
        };

        this.checkEventData = debounce(this.checkEventData, 2000);

        // const shouldForce = false;
        const shouldForce = this.props.targetTinyurl
            && this.props.event?.detail?.tinyUrl
            && this.props.event?.detail?.tinyUrl !== this.props.targetTinyurl;
        const isFromSSR = isFromServer();
        const isLockedEvent = this.props?.event?.detail?.configuration?.companion?.requireAuthenticationForContent;

        if (!__SERVERSIDE__ && (!isFromSSR || isLockedEvent)) {
            if (!isFromSSR) {
                this.trackMail();
            }
            eventModule().then((mod) => {
                const store = this.props.store;
                if (shouldForce) {
                    mod.bootstrapEventActions.eventBootstrap(
                        isFromSSR,
                        this.props.location,
                        this.props.match,
                        null,
                        this.props.targetTinyurl,
                        shouldForce
                    )(store.dispatch, store.getState);
                } else {
                    mod.bootstrapEventActions.eventBootstrap(
                        isFromSSR,
                        this.props.location,
                        this.props.match,
                        this.props.eventid,
                        this.props.tinyurl
                    )(store.dispatch, store.getState);
                }
            });
        } else {
            this.trackMail();
        }
    }

    componentDidMount() {
        setTimeout(() => {
            import("../../../services/activityservice").then((mod) => {
                mod.userActivityActions.trackCurrentPage();
            });
        }, 1000);
    }

    trackMail() {
        if (this.props.event?.requestManagers?.apiFront
            && this.props.event?.detail?.id
            && this.props.location.search && this.props.location.search.indexOf('iw_mailid=') > -1) {
            const args = parse(this.props.location.search);
            const iwmailid = args?.iw_mailid;
            if (iwmailid && GuidPattern.test(iwmailid)) {
                // tracking d'email
                eventMailTracking(
                    this.props.event?.requestManagers?.apiFront,
                    this.props.event?.detail?.id,
                    iwmailid
                );
            }
        }
    }

    checkEventData = () => {
        eventModule().then((mod) => {
            const store = this.props.store;
            mod.syncEventActions.checkEventData()(store.dispatch, store.getState);
        });
    };

    componentDidUpdate(prevprops: IAppEventDataProps) {
        if (this.props.user && this.props.user.currentUser && actionQueue.hasItems()) {
            eventActionQueueModule().then((mod) => {
                const store = this.props.store;
                mod.actions.checkActionQueue()(store.dispatch, store.getState);
            });
        }

        if (this.props.location.pathname !== prevprops.location.pathname) {
            this.checkEventData();
        }

        if (!this.onboardingDisplay) {
            if (this.props.event?.eventReady !== prevprops.event?.eventReady) {
                if (this.props.user && this.props.user.currentUser) {
                    this.onboardingDisplay = true;
                    eventUserBootstrapModule().then((mod) => {
                        const store = this.props.store;
                        mod.userBootstrapActions.onBoarding()(store.dispatch, store.getState);
                    });
                }
            }

            if (this.props.user && this.props.user.currentUser !== prevprops.user?.currentUser
                && this.props.event?.eventReady) {
                this.onboardingDisplay = true;
                eventUserBootstrapModule().then((mod) => {
                    const store = this.props.store;
                    mod.userBootstrapActions.onBoarding()(store.dispatch, store.getState);
                });
                if (moment.locale() !== this.props.i18n?.currentLanguageCode) {
                    eventModule().then((mod) => {
                        const store = this.props.store;
                        mod.bootstrapEventActions.eventBootstrap(
                            isFromServer(),
                            this.props.location,
                            this.props.match,
                            this.props.eventid,
                            this.props.tinyurl,
                            true
                        )(store.dispatch, store.getState);
                    });
                }

            }
        }
    }

    render() {
        uilogger.verbose("render event data");
        let content;

        if (this.props.event?.eventReady) {
            content = this.props.children;
        }

        //add className with user kinds
        const userKinds = this.props.user?.currentUser?.detail?.kinds;
        let userKind = "";

        if (userKinds) {
            userKinds.forEach((uk) => {
                userKind = userKind.concat(`kind-${toUrlSlug(uk)}`, " ");
                return userKind;
            });
        }

        const cookiebarCfg = this.props.event?.detail?.configuration?.companion?.cookiesWarning;
        const trackingCfg = this.props.event?.detail?.configuration?.companion?.tracking;

        return (
            <CookieTracking
                i18n={this.props.i18n}
                eventId={this.props.event?.detail?.id}
                websiteId={null}
                cookieid={this.props.event?.detail?.id}
                config={cookiebarCfg}
                tracking={trackingCfg}
            >
                {(cookiebar, contentClassName) => {                    
                    const classNames = `app-shell event-${this.props.event?.eventid} ${contentClassName || ''} ${userKind}`;


                    if (this.container?.current) {
                        if (classNames !== this.container.current.className) {
                            this.container.current.className = classNames;
                        }
                    }

                    return (<div ref={this.container} className={classNames}>
                        <div className={"app-shell-data " + (this.props.className || "")}>
                            <VisualConfiguration
                                visualConfiguration={this.props.visualConfiguration}
                                prefix={".event-" + (this.props.event?.detail ? this.props.event.detail.id : "") + " "}
                            />
                            {content}
                        </div>
                        {cookiebar}
                    </div>
                    );
                }}
            </CookieTracking>
        );
    }
}

function mapAppEventDataDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const AppEventData: new (any) => React.Component<IAppEventDataProps, any> = connect(
    null,
    mapAppEventDataDispatchToProps
)(wrapReduxStore(AppEventDataComponent) as any) as any;

export interface ISyncPanelProps extends IAppEventDataProps {
    onSyncCompleted: (hasError?: boolean) => void;
    forceSync: boolean;
    store: IInwinkStore;
}
