import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { States } from '@@services/services';
import { RealtimeManagerContext, connectRealtimeHost, realtimeHost, IRealtimeManager } from '@@services/realtimeservice';

export interface IEventRealtimeProps {
    event?: States.IEventState;
    user?: States.IAppUserState;
    children?: React.ReactNode;
}

export interface IEventRealtimeState {
    manager: Promise<IRealtimeManager>;
}

@connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        user: state.user
    };
})
export class EventRealtime extends React.Component<IEventRealtimeProps, IEventRealtimeState> {
    constructor(props : IEventRealtimeProps) {
        super(props);

        this.state = {
            manager: this.getCurrentManager(props)
        };
    }

    componentDidUpdate(prevprops: IEventRealtimeProps) {
        const eventChanged = prevprops.event.eventid !== this.props.event.eventid;
        const notInitialized = !this.state.manager && this.props.event.detail;

        if (eventChanged || notInitialized) {
            this.setState({
                manager: this.getCurrentManager(this.props)
            });
        }

        if (this.props.user.currentUser?.detail !== prevprops.user.currentUser?.detail) {
            this.state.manager.then((mgr) => mgr.registerUser(this.props.event.eventid));
        }
    }

    getCurrentManager(props: IEventRealtimeProps) {
        if (__SERVERSIDE__) {
            return null;
        }

        if (props.event && props.event.detail) {
            let host = props.event.detail.configuration?.realtime?.default;
            if (!host) {
                host = realtimeHost();
            }
            const manager = connectRealtimeHost(host);

            if (InWinkPreview || props.user.currentUser?.detail) {
                manager.then((mgr) => {
                    mgr.init();
                    mgr.registerUser(props.event.eventid);
                });
            }
            return manager;
        }
    }

    render() {
        return <RealtimeManagerContext.Provider value={this.state.manager}>
            {this.props.children}
        </RealtimeManagerContext.Provider>;
    }
}
