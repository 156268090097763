import { AnimatedSwitch } from '@inwink/animatedswitch';
import type { Entities } from '@inwink/entities/entities';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { IAppShellEventProps } from "./appshell.event.props";
import { getPageClass, AsyncPage } from "../../../components/page";
import { PageTransition } from "../../../components/transitions";
import { modulesActions } from '../../../routes/appmodules';
import { PersonDetailPage } from '../routes/persondetail/persondetail';
import { actions as i18nActions, AppLabel, IBestLanguage } from '../../../services/i18nservice';
import type { States } from '@@services/services';

import "./appshell.routes.less";
import { AppShellEventCopilot, AppShellEventCopilotTrigger } from './appshell.copilot';

export interface IAppShellEventRoutesProps extends IAppShellEventProps {
    modulesActions: typeof modulesActions;
    i18nActions: typeof i18nActions;
    children?: React.ReactNode;
}

class AppShellEventRoutesComponent extends React.Component<IAppShellEventRoutesProps, any> {
    currentMatch: string;

    constructor(props: IAppShellEventRoutesProps) {
        super(props);

        this.state = {
            routes: this.computeRoutes(props)
        };
    }

    componentDidUpdate(prevProps: IAppShellEventRoutesProps) {
        if (this.props.event && ((this.props.event && this.props.event.eventid)
            !== (prevProps.event && prevProps.event.eventid))) {
            this.setState({ routes: this.computeRoutes(this.props) });
        }
    }

    computeRoutes(props: IAppShellEventProps) {
        const rootPath = props.match.path;
        return computeEventRoutes(rootPath, props.event && props.event.detail);
    }

    // On rends la section à travers cette fonction pour des réseau d'accessibilités (id & tabindex)
    renderSection = (props) => {
        const classes = ["app-pages"];

        if (this.props.visualConfiguration) {
            if (this.props.visualConfiguration.global && this.props.visualConfiguration.global.appTheme) {
                classes.push(this.props.visualConfiguration.global.appTheme);
            }
        }

        if (this.props.copilotConfiguration?.enabled === true) {
            classes.push("with-assistant");
            return <section id="main-content" className={classes.join(' ')}>
                <AppShellEventCopilot {...this.props} />
                <div className="appshell-pagewrapper">
                    {props.children}
                    <AppShellEventCopilotTrigger {...this.props} />
                </div>
                
            </section>;    
        }

        return <section id="main-content" className={classes.join(' ')}>
            {props.children}
        </section>;
    };

    render() {
        const classes = ["app-pages"];

        if (this.props.visualConfiguration) {
            if (this.props.visualConfiguration.global && this.props.visualConfiguration.global.appTheme) {
                classes.push(this.props.visualConfiguration.global.appTheme);
            }
        }

        const routes = this.state.routes.map((r) => <Route
            key={r.path || 'Une erreur est survenue'}
            exact={r.exact}
            path={r.path}
            render={(pp: any) => {
                const props = pp;
                this.currentMatch = props.match.url;
                if (this.currentMatch[this.currentMatch.length - 1] !== '/') {
                    this.currentMatch += '/';
                }

                const renderProps: any = {
                    ...props,
                    event: this.props.event,
                    rootwebsite: this.props.rootwebsite,
                    user: this.props.user,
                    i18n: this.props.i18n,
                    modulesActions: this.props.modulesActions,
                    webmasterConfiguration: this.props.webmasterConfiguration,
                    visualConfiguration: this.props.visualConfiguration,
                    pagefooter: this.props.pagefooter,
                    visualstate: this.props.visualstate
                };

                if (r.routekey) {
                    renderProps.key = r.routekey(renderProps);
                }

                if (r.lngcode) {
                    props.match.params.lngcode = r.lngcode;
                }

                const lngcode = props.match.params.lngcode;
                if (lngcode && this.props.location.pathname === props.location.pathname
                    && lngcode !== this.props.i18n.currentLanguageCode) {
                    const lng = ((this.props.i18nActions.determineBestLanguage(lngcode)) as any) as IBestLanguage;
                    this.props.i18nActions.loadLanguage(lng.language, lng.dateDisplayLanguage);
                }

                if (r.render) {
                    return r.render(renderProps);
                }

                return React.createElement(r.component, renderProps);
            }}
        />);

        const style: any = {};
        if (this.props.visualConfiguration && this.props.visualConfiguration.global) {
            style.backgroundColor = this.props.visualConfiguration.global.backgroundColor;
            if (this.props.visualConfiguration.global.backgroundImage) {
                style.backgroundImage = "url(\"" + this.props.visualConfiguration.global.backgroundImage + "\")";
            }
        }

        let currentLocationKey = this.props.location.key;
        if (!currentLocationKey) {
            currentLocationKey = this.props.location.pathname + this.props.match.params;
        }

        return <AnimatedSwitch
            location={this.props.location}
            match={this.props.match}
            transitionComponent={PageTransition}
            component={this.renderSection}
            style={style}
        >
            {routes}
        </AnimatedSwitch>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        modulesActions: bindActionCreators(modulesActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const AppShellEventRoutes: new (any) => React.Component<IAppShellEventProps, any> = connect(
    null,
    mapDispatchToProps)(withRouter(AppShellEventRoutesComponent as any) as any) as any;

function addRoutes(routes: any[], _rootPath: string, eventDetail: Entities.IEventDetail, lngcode?: string) {
    let rootPath = _rootPath;
    if (rootPath === "/") rootPath = "";

    routes.push(
        {
            path: rootPath + "/",
            exact: true,
            lngcode: lngcode,
            component: getPageClass("home", "homepage")
        },
        {
            path: rootPath + '/content/:contentpagetemplate',
            exact: true,
            lngcode: lngcode,
            routekey: (props) => props.match.params.contentpagetemplate,
            component: getPageClass((args) => {
                return args.match && args.match.params.contentpagetemplate;
            }, "eventcontentpage", { contenttype: "contentpage" })
        },
        {
            path: rootPath + '/eventagenda',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("eventagenda", "eventagendapage pagefullsize", { entitykind: "Session" })
        },
        {
            path: rootPath + '/registration',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "registrationpage" */ '../routes/registration/registration').then((page) => {
                            complete(page.RegistrationPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/passregistration',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "passregistrationpage" */ '../routes/registration/passregistration')
                            .then((page) => {
                                complete(page.PassRegistrationPage);
                            });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/registration/:registrationtemplate',
            routekey: (props) => props.match.params.registrationtemplate,
            exact: true,
            lngcode: lngcode,
            component: getPageClass((args) => args.match?.params?.registrationtemplate, "registrationpage", {
                entitykind: "Person",
                contenttype: "registration"
            })
        },
        {
            path: rootPath + '/orderredirect',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "orderredirect" */ '../routes/order/orderredirect')
                            .then((page) => {
                                complete(page.OrderRedirectPage);
                            });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/search',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("globalsearch", "globalsearchpage")
        }
    );

    routes.push(
        {
            path: rootPath + '/sessions',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("allsessions", "allsessionspage", { entitykind: 'Session' })
        },
        {
            path: rootPath + '/session/next',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "nextsessionpage" */ '../routes/nextsession/nextsessionpage').then((page) => {
                            complete(page.NextSessionPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/session/:sessionid/:slug?',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("sessiondetail", "sessiondetailpage",
                {
                    entitykind: "Session",
                    entityid: (args) => (args.match?.params?.sessionid?.toLowerCase() || ""),
                    requireEntity: true
                })
        }
    );

    if (inwink.config.env === "dev") {
        routes.push({
            path: rootPath + '/session/record/:sessionid',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("module.videorecorder") as any;
                        p.then((recorder) => {
                            complete(recorder.SessionVideoRecorderPage);
                        });
                    }}
                />;
            }
        });
    }

    routes.push({
        path: rootPath + '/eventtheme/:eventthemeid',
        exact: true,
        lngcode: lngcode,
        component: getPageClass("eventthemedetail", "eventthemedetailpage",
            {
                entitykind: "EventTheme",
                entityid: (args) => (args.match?.params?.eventthemeid?.toLowerCase() || ""),
                requireEntity: true
            })
    });

    routes.push({
        path: rootPath + '/journey/:journeyid',
        exact: true,
        lngcode: lngcode,
        component: getPageClass("journeydetail", "journeydetailpage",
            {
                entitykind: "Journey",
                entityid: (args) => (args.match?.params?.journeyid?.toLowerCase() || ""),
                requireEntity: true
            })
    },
    {
        path: rootPath + '/journeys',
        exact: true,
        lngcode: lngcode,
        component: getPageClass("alljourneys", "alljourneyspage", { entitykind: 'Journey' })
    },);

    routes.push({
        path: rootPath + '/speaker/:personid/:slug?',
        exact: true,
        lngcode: lngcode,
        // component: PersonDetailPage
        // eslint-disable-next-line react/display-name
        render: (props: any) => {
            return <PersonDetailPage {...props} personType="speaker" />;
        }
    });

    routes.push({
        path: rootPath + '/contact/:personid',
        exact: true,
        lngcode: lngcode,
        // component: PersonDetailPage
        // eslint-disable-next-line react/display-name
        render: (props: any) => {
            return <PersonDetailPage {...props} personType="person" />;
        }
    });

    routes.push({
        path: rootPath + '/speakers',
        exact: true,
        lngcode: lngcode,
        component: getPageClass("allspeakers", "allspeakerspage", { entitykind: 'Speaker' })
    });

    routes.push({
        path: rootPath + '/attendee/:personid',
        exact: true,
        lngcode: lngcode,
        component: PersonDetailPage
    });

    routes.push({
        path: rootPath + '/videochat',
        exact: true,
        lngcode: lngcode,
        // eslint-disable-next-line react/display-name
        render: (props: any) => {
            return <AsyncPage
                componentProps={props}
                getComponent={(complete) => {
                    // import(/* webpackChunkName: "mod-videochatpage" */ "../routes/videochat/videochat").then((mod) => {
                    //     complete(mod.VideoChatPage);
                    // });
                    import(/* webpackChunkName: "mod-videochatpagev2" */
                        "@@modules/event/features/videochat/videochatpage").then((mod) => {
                        complete(mod.VideoChatPage);
                    });
                }}
            />;
        }
    });

    routes.push({
        path: rootPath + '/session/:sessionid/watchtogether/:watchtogetherid',
        exact: true,
        lngcode: lngcode,
        // eslint-disable-next-line react/display-name
        render: (props: any) => {
            return <AsyncPage
                componentProps={props}
                getComponent={(complete) => {
                    import(/* webpackChunkName: "mod-watchtogetherpage" */
                        "@@modules/event/features/sessions/watchtogether/watchtogetherpage"
                    ).then((mod) => {
                        complete(mod.WatchTogetherPage);
                    });
                }}
            />;
        }
    });

    routes.push({
        path: rootPath + '/onlinesession/:sessionid/:slug?',
        exact: true,
        lngcode: lngcode,
        render: function OnlineSessionPage(props: any) {
            return <AsyncPage
                componentProps={props}
                className="onlinesessionpage bloctheme"
                getComponent={(complete) => {
                    import(
                        /* webpackChunkName: "mod-onlinesessionpage" */
                        '../features/sessions/onlinesessionpage'
                    ).then((mod) => {
                        complete(mod.OnlineSessionPage);
                    });
                }}
            />;
        }
    });

    routes.push({
        path: rootPath + '/room/:roomid',
        exact: true,
        lngcode: lngcode,
        // eslint-disable-next-line react/display-name
        render: (props: any) => {
            return <AsyncPage
                componentProps={props}
                getComponent={(complete) => {
                    import(/* webpackChunkName: "mod-onlineroomdetail" */ "../routes/onlineroom/onlineroomdetail").then((mod) => {
                        complete(mod.RoomDetailPage);
                    });
                }}
            />;
        }
    });

    routes.push({
        path: rootPath + '/onlineroom/:roomid',
        exact: true,
        lngcode: lngcode,
        // eslint-disable-next-line react/display-name
        render: (props: any) => {
            return <AsyncPage
                componentProps={props}
                getComponent={(complete) => {
                    import(/* webpackChunkName: "mod-onlineroompage" */ "../features/room/onlineroompage").then((mod) => {
                        complete(mod.OnlineRoomPage);
                    });
                }}
            />;
        }
        // component: OnlineRoomPage
    });

    // routes.push({
    //     path: rootPath + '/livebroadcast/:sessionid',
    //     exact: true,
    //     lngcode: lngcode,
    //     component: LiveBroadcastPage
    // });
    routes.push(
        {
            path: rootPath + '/exhibitors',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("allexhibitors", "allexhibitorspage", { entitykind: 'Exhibitor' })
        },
        {
            path: rootPath + '/exhibitor/:exhibitorid',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("exhibitordetail", "exhibitordetailpage",
                {
                    entitykind: "Exhibitor",
                    entityid: (args) => (args.match?.params?.exhibitorid?.toLowerCase() || ""),
                    requireEntity: true
                })
        },
        {
            path: rootPath + '/partners',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("allexhibitors", "allexhibitorspage", { entitykind: 'Exhibitor' })
        },
        {
            path: rootPath + '/partners/:exhibitorid',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("exhibitordetail", "exhibitordetailpage",
                {
                    entitykind: "Exhibitor",
                    entityid: (args) => (args.match?.params?.exhibitorid?.toLowerCase() || ""),
                    requireEntity: true
                })
        },
        {
            path: rootPath + '/partner/:exhibitorid/:slug?',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("exhibitordetail", "exhibitordetailpage",
                {
                    entitykind: "Exhibitor",
                    entityid: (args) => (args.match?.params?.exhibitorid?.toLowerCase() || ""),
                    requireEntity: true
                })
        },
        {
            path: rootPath + '/partner-workspace',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("partner-workspace", "partnerworkspacepage", { entitykind: "Exhibitor" })
        },
        {
            path: rootPath + '/partner-workspace/r/:target/:content?',
            lngcode: lngcode,
            component: getPageClass("partner-workspace", "partnerworkspacepage", { entitykind: "Exhibitor" })
        },
        {
            path: rootPath + '/speaker-workspace',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("speaker-workspace", "speakerworkspacepage", { entitykind: "Person" })
        },
        {
            path: rootPath + '/speaker-workspace/r/:target/:content?',
            lngcode: lngcode,
            component: getPageClass("speaker-workspace", "speakerrworkspacepage", { entitykind: "Person" })
        },
        {
            path: rootPath + '/user-workspace',
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-userworkspace") as any;
                        p.then((userworkspace) => {
                            complete(userworkspace.indexRoute);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/user-workspace/:target/:content?',
            lngcode: lngcode,
            component: getPageClass("user-workspace", "userworkspacepage", { entitykind: "Person" })
        }
    );

    routes.push(
        {
            path: rootPath + '/offerings',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("allofferings", "allofferingspage", { entitykind: 'ExhibitorOffering' })
        },
        {
            path: rootPath + '/offering/:offeringid/:slug?',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("offeringdetail", "offeringdetailpage", {
                entitykind: "ExhibitorOffering",
                entityid: (args) => {
                    return args.match?.params?.offeringid?.toLowerCase() || "";
                },
                requireEntity: true
            })
        }
    );

    routes.push({
        path: rootPath + '/thematictrails',
        exact: true,
        lngcode: lngcode,
        component: getPageClass("thematictrails", "thematictrailspage")
    });

    routes.push(
        {
            path: rootPath + '/attendees',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("allattendees", "allattendeespage", { allowedOnly: true, entitykind: "Person" })
        },
        {
            path: rootPath + '/contacts',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("contacts", "contactspage", { allowedOnly: true })
        },
        {
            path: rootPath + '/chat',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("discussionthread", "discussionthreadpage", { allowedOnly: true })
        },
        {
            path: rootPath + '/myprofile',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("userprofile", "userprofilepage", { allowedOnly: true, entitykind: "Person" })
        },
    );

    routes.push({
        path: rootPath + '/myagenda',
        exact: true,
        lngcode: lngcode,
        component: getPageClass("agenda", "agendapage", { entitykind: 'Session' })
    });

    routes.push(
        {
            path: rootPath + '/eventmessages',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("alleventmessages", "alleventmessagespage", { entitykind: 'EventMessage' })
        },
        {
            path: rootPath + '/messageswall',
            exact: true,
            lngcode: lngcode,
            component: getPageClass("eventmessageswall", "eventmessageswallpage", { entitykind: 'EventMessage' })
        }
    );

    routes.push({
        path: rootPath + '/me',
        lngcode: lngcode,
        // component: MePage
        // eslint-disable-next-line react/display-name
        render: (props: { modulesActions: typeof modulesActions, event: States.IEventState }) => {
            const hasAttendeeWorkSpace = props.event?.detail?.configuration?.attendees?.useAttendeesCockpit;
            if (hasAttendeeWorkSpace) {
                return <Redirect to="user-workspace" />;
            }
            return <AsyncPage
                componentProps={props}
                getComponent={(complete) => {
                    const eventModule = props.modulesActions.getModule("mod-eventuser") as any;
                    eventModule.then(() => {
                        const p = props.modulesActions.getModule("mod-me") as any;
                        p.then((mod) => {
                            complete(mod.MePage);
                        });
                    });
                }}
            />;
        }
    });

    // routes.push({
    //     path: rootPath + '/me',
    //     exact: true,
    //     lngcode : lngcode,
    //     component: getPageClass("me", "mepage", { entitykind: 'Person', allowedOnly: true })
    // });

    routes.push(
        {
            path: rootPath + '/unregister',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "unregisterpage" */ '../routes/unregister/unregister').then((page) => {
                            complete(page.UnregisterPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/unsuscribenotifications',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "unsuscribepage" */
                            '../routes/unsuscribenotifications/unsuscribe'
                        ).then((page) => {
                            complete(page.UnsuscribeNotificationsPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/unregistration',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "unsuscribepage" */
                            '../routes/registration/unregistration'
                        ).then((page) => {
                            complete(page.RefuseRegistrationPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/refuseinvitation',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "refuseinvitationpage" */
                            '../routes/registration/refuseinvitation'
                        ).then((page) => {
                            complete(page.RefuseInvitationPage);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/livesession/:livesessionid',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-livesession") as any;
                        p.then((livesession) => {
                            complete(livesession.LiveSessionPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/livesessionwall/:livesessionid',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-livesessionwall") as any;
                        p.then((livesession) => {
                            complete(livesession.LiveSessionWall);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/livesessionboard/:livesessionid',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-livesessionboard") as any;
                        p.then((livesessionboard) => {
                            complete(livesessionboard.LiveSessionBoardPage);
                        });
                    }}
                />;
            }
        },
    );

    routes.push(
        {
            path: rootPath + '/scans',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-partnerscan") as any;
                        p.then((partnerscan) => {
                            complete(partnerscan.PartnerScanPage);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/scans/:exhibitorworkspaceid',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-partnerscan") as any;
                        p.then((partnerscan) => {
                            complete(partnerscan.PartnerScanPage);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/partnerscan',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-partnerscan") as any;
                        p.then((partnerscan) => {
                            complete(partnerscan.PartnerScanPage);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/partnerscans',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-partnerscan") as any;
                        p.then((partnerscan) => {
                            complete(partnerscan.PartnerScanPage);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/partnerscans/:exhibitorworkspaceid',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-partnerscan") as any;
                        p.then((partnerscan) => {
                            complete(partnerscan.PartnerScanPage);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/partner-workspace/:exhibitorworkspaceid',
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-partnerworkspace") as any;
                        p.then((partnerworkspace) => {
                            complete(partnerworkspace.indexRoute);
                        });
                    }}
                />;
            }
        }
    );

    routes.push(
        {
            path: rootPath + '/speaker-workspace/:speakerworkspaceid',
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-speakerworkspace") as any;
                        p.then((speakerworkspace) => {
                            complete(speakerworkspace.indexRoute);
                        });
                    }}
                />;
            }
        }
    );
}

export function computeEventRoutes(rootPath: string, eventDetail: Entities.IEventDetail) {
    const routes = [];
    // if (eventDetail && eventDetail.configuration) {
    //     const cfg = eventDetail.configuration;
    //     if (cfg && cfg.global && cfg.global.supportedLanguages && cfg.global.supportedLanguages.length > 1) {
    //         cfg.global.supportedLanguages.forEach((l) => {
    //             let path = rootPath + '/' + l;
    //             if (rootPath === "/") {
    //                 path = "/" + l;
    //             }
    //             addRoutes(routes, path, eventDetail, l);
    //         });
    //     }
    // }

    addRoutes(routes, rootPath, eventDetail);

    routes.push({
        // eslint-disable-next-line react/display-name
        component: () => <div className="app-page">
            <div className="dynamicpage pagenotfound">
                <div className="update-container">
                    <AppLabel component="div" className="message" i18n="page.notfound" />
                </div>
            </div>
        </div>
    });

    return routes;
}
